exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessories-js": () => import("./../../../src/pages/accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-deliveryandhook-upservice-js": () => import("./../../../src/pages/deliveryandhook-upservice.js" /* webpackChunkName: "component---src-pages-deliveryandhook-upservice-js" */),
  "component---src-pages-digital-catalog-js": () => import("./../../../src/pages/digital-catalog.js" /* webpackChunkName: "component---src-pages-digital-catalog-js" */),
  "component---src-pages-faq-general-js": () => import("./../../../src/pages/faq/general.js" /* webpackChunkName: "component---src-pages-faq-general-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-faq-pedi-spa-chair-js": () => import("./../../../src/pages/faq/pedi-spa-chair.js" /* webpackChunkName: "component---src-pages-faq-pedi-spa-chair-js" */),
  "component---src-pages-faq-shipping-js": () => import("./../../../src/pages/faq/shipping.js" /* webpackChunkName: "component---src-pages-faq-shipping-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ip-js": () => import("./../../../src/pages/ip.js" /* webpackChunkName: "component---src-pages-ip-js" */),
  "component---src-pages-limitedwarranty-js": () => import("./../../../src/pages/limitedwarranty.js" /* webpackChunkName: "component---src-pages-limitedwarranty-js" */),
  "component---src-pages-manuals-and-downloads-index-js": () => import("./../../../src/pages/manuals-and-downloads/index.js" /* webpackChunkName: "component---src-pages-manuals-and-downloads-index-js" */),
  "component---src-pages-manuals-and-downloads-magna-js": () => import("./../../../src/pages/manuals-and-downloads/magna.js" /* webpackChunkName: "component---src-pages-manuals-and-downloads-magna-js" */),
  "component---src-pages-manuals-and-downloads-majestic-js": () => import("./../../../src/pages/manuals-and-downloads/majestic.js" /* webpackChunkName: "component---src-pages-manuals-and-downloads-majestic-js" */),
  "component---src-pages-manuals-and-downloads-novo-js": () => import("./../../../src/pages/manuals-and-downloads/novo.js" /* webpackChunkName: "component---src-pages-manuals-and-downloads-novo-js" */),
  "component---src-pages-manuals-and-downloads-novo-x-js": () => import("./../../../src/pages/manuals-and-downloads/novo-x.js" /* webpackChunkName: "component---src-pages-manuals-and-downloads-novo-x-js" */),
  "component---src-pages-manuals-and-downloads-tergo-js": () => import("./../../../src/pages/manuals-and-downloads/tergo.js" /* webpackChunkName: "component---src-pages-manuals-and-downloads-tergo-js" */),
  "component---src-pages-manuals-and-downloads-v-sport-n-js": () => import("./../../../src/pages/manuals-and-downloads/v-sport-n.js" /* webpackChunkName: "component---src-pages-manuals-and-downloads-v-sport-n-js" */),
  "component---src-pages-manuals-and-downloads-v-sport-t-js": () => import("./../../../src/pages/manuals-and-downloads/v-sport-t.js" /* webpackChunkName: "component---src-pages-manuals-and-downloads-v-sport-t-js" */),
  "component---src-pages-manuals-and-downloads-vip-js": () => import("./../../../src/pages/manuals-and-downloads/vip.js" /* webpackChunkName: "component---src-pages-manuals-and-downloads-vip-js" */),
  "component---src-pages-pedicure-spa-chairs-index-js": () => import("./../../../src/pages/pedicure-spa-chairs/index.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-index-js" */),
  "component---src-pages-pedicure-spa-chairs-magna-js": () => import("./../../../src/pages/pedicure-spa-chairs/magna.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-magna-js" */),
  "component---src-pages-pedicure-spa-chairs-majestic-js": () => import("./../../../src/pages/pedicure-spa-chairs/majestic.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-majestic-js" */),
  "component---src-pages-pedicure-spa-chairs-novo-js": () => import("./../../../src/pages/pedicure-spa-chairs/novo.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-novo-js" */),
  "component---src-pages-pedicure-spa-chairs-novox-js": () => import("./../../../src/pages/pedicure-spa-chairs/novox.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-novox-js" */),
  "component---src-pages-pedicure-spa-chairs-tergo-js": () => import("./../../../src/pages/pedicure-spa-chairs/tergo.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-tergo-js" */),
  "component---src-pages-pedicure-spa-chairs-vip-js": () => import("./../../../src/pages/pedicure-spa-chairs/vip.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-vip-js" */),
  "component---src-pages-pedicure-spa-chairs-vsport-js": () => import("./../../../src/pages/pedicure-spa-chairs/vsport.js" /* webpackChunkName: "component---src-pages-pedicure-spa-chairs-vsport-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-purchasetermsandconditions-js": () => import("./../../../src/pages/purchasetermsandconditions.js" /* webpackChunkName: "component---src-pages-purchasetermsandconditions-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-shippingterms-js": () => import("./../../../src/pages/shippingterms.js" /* webpackChunkName: "component---src-pages-shippingterms-js" */),
  "component---src-pages-vi-clientbenefits-js": () => import("./../../../src/pages/vi/clientbenefits.js" /* webpackChunkName: "component---src-pages-vi-clientbenefits-js" */),
  "component---src-pages-vi-contego-benefits-js": () => import("./../../../src/pages/vi/contego-benefits.js" /* webpackChunkName: "component---src-pages-vi-contego-benefits-js" */),
  "component---src-pages-vi-ourproducts-js": () => import("./../../../src/pages/vi/ourproducts.js" /* webpackChunkName: "component---src-pages-vi-ourproducts-js" */),
  "component---src-pages-vi-ownerbenefits-js": () => import("./../../../src/pages/vi/ownerbenefits.js" /* webpackChunkName: "component---src-pages-vi-ownerbenefits-js" */),
  "component---src-pages-vi-productdetails-js": () => import("./../../../src/pages/vi/productdetails.js" /* webpackChunkName: "component---src-pages-vi-productdetails-js" */),
  "component---src-pages-vi-smart-investment-js": () => import("./../../../src/pages/vi/smart-investment.js" /* webpackChunkName: "component---src-pages-vi-smart-investment-js" */),
  "component---src-pages-vi-techbenefits-js": () => import("./../../../src/pages/vi/techbenefits.js" /* webpackChunkName: "component---src-pages-vi-techbenefits-js" */),
  "component---src-pages-vi-thank-you-js": () => import("./../../../src/pages/vi/thank-you.js" /* webpackChunkName: "component---src-pages-vi-thank-you-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-wecare-js": () => import("./../../../src/pages/wecare.js" /* webpackChunkName: "component---src-pages-wecare-js" */),
  "component---src-pages-why-disposable-jet-js": () => import("./../../../src/pages/why-disposable-jet.js" /* webpackChunkName: "component---src-pages-why-disposable-jet-js" */),
  "component---src-pages-whycontego-js": () => import("./../../../src/pages/whycontego.js" /* webpackChunkName: "component---src-pages-whycontego-js" */)
}

