/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require('react')
const FacebookMessengerChat = require('./src/components/FacebookMessengerChat').default

require('bootstrap/dist/css/bootstrap.min.css')
require('./src/styles/global.scss')

exports.wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <div id="fb-root" />
      <FacebookMessengerChat />
    </>
  )
}

exports.onRouteUpdate = () => {
  const headerElement = document.getElementById('header')
  if (headerElement) {
    headerElement.scrollIntoView()
  }
}
