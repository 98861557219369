import React, { useEffect } from 'react'

const facebookAppId = '1445135705721986'
const blackListAddresses = [
  'https://staginggatsby.contegospa.com/vi',
  'https://www.contegospa.com/vi',
  'https://www.contegospa.com/why-disposable-jet',
  'https://www.contegospa.com/wecare',
  'https://www.contegospa.com/whycontego',
  'https://www.contegospa.com/digital-catalog',
  'https://www.contegospa.com/reviews'
]

const FacebookMessengerChat = () => {
  useEffect(() => {
    if (blackListAddresses.findIndex(value => window.location.href.includes(value)) === -1) {
      window.fbAsyncInit = () => {
        window.FB.init({
          xfbml: true,
          version: 'v5.0'
        })
      }
      ;((d, s, id) => {
        let js = d.getElementsByTagName(s)[0]
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }
  }, [])

  return (
    <>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id={facebookAppId}
        theme_color="#0084ff"
        logged_in_greeting="Hi, welcome to Contégo Spa Designs! How can we help you?"
        logged_out_greeting="Hi, welcome to Contégo Spa Designs! How can we help you?"
      />
    </>
  )
}

export default FacebookMessengerChat
